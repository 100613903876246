var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"},[_c('div',{staticClass:"w-100 d-flex justify-content-between align-items-center",staticStyle:{"background":"#dadada"}},[_c('router-link',{staticClass:"btn btn-lg btn-secondary m-2",attrs:{"to":"/"}},[_c('i',{staticClass:"bi bi-chevron-left"})]),_c('StoreCylinderScanner'),_c('button',{staticClass:"btn btn-lg btn-secondary m-2",on:{"click":_vm.cancelPickup}},[_c('i',{staticClass:"bi bi-x-octagon"})]),(_vm.$store.state.production.valid || !_vm.showError)?_c('button',{staticClass:"btn btn-lg btn-success flex-fill m-2",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.doProduction}},[(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm me-2",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v("Wyprodukuj ")]):_vm._e(),(!_vm.$store.state.production.valid && _vm.showError)?_c('ValidationErrorsModal',{attrs:{"type":"button"}}):_vm._e()],1),_c('div',{staticClass:"bg-light p-2 d-flex justify-content-between mb-2 w-100"},[_c('span',{staticClass:"col-9"},[_vm._v("Produkcja towaru")]),_c('div',{staticClass:"col-3"},[_c('span',{staticClass:"bg-secondary me-2 ms-0 h-100 badge fs-5 d-flex align-items-center justify-content-center"},[_vm._v(_vm._s(_vm.$store.getters["production/countAllCylinders"]))])])]),(_vm.items.length == 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Dodaj produkt lub zeskanuj butlę aby rozpocząć proces napełniania ")]):_vm._e(),_c('div',{staticClass:"w-100 p-2 pb-5 flex-grow-1",staticStyle:{"overflow":"auto"}},[_vm._l((_vm.items),function(item,key){return _c('router-link',{key:key,staticClass:"p-2 text-dark d-flex justify-content-between mb-2 align-items-center",class:_vm.$store.getters['production/isLastScannedProduct'](item.product_id)
          ? 'bg-lightgreen'
          : 'bg-light',attrs:{"to":'/production/product/' + item.product_id}},[_c('span',{staticClass:"flex-fill pe-2"},[_vm._v(_vm._s(item.name)+" ")]),_c('span',{staticClass:"bg-secondary col-3 badge fs-5 d-flex align-items-center justify-content-center",class:{
          'bg-danger':
            _vm.$store.getters['production/getErrors']({ product_id: item.product_id }).length > 0,
          'bg-warning':
            _vm.$store.getters['production/getWarnings']({ product_id: item.product_id }).length > 0,
        }},[_vm._v(_vm._s(_vm.$store.getters["production/countCylinder"](item.product_id))+" ")])])}),(_vm.badCylinders.length > 0)?_c('router-link',{staticClass:"bg-light p-2 text-dark d-flex justify-content-between mb-2 align-items-center",attrs:{"to":'/production/badcylinder'}},[_c('span',{staticClass:"flex-fill pe-2 text-danger"},[_vm._v("Butle nie pasujące")]),_c('span',{staticClass:"bg-danger col-3 badge fs-5 d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.badCylinders.length))])]):_vm._e()],2),_c('router-link',{staticClass:"d-flex justify-content-center align-items-center fs-1 text-dark",staticStyle:{"width":"50px","height":"50px","background":"#dadada","border-radius":"5px","font-weight":"bold","position":"fixed","right":"10px","bottom":"10px","z-index":"1000"},attrs:{"to":"/production/selectproduct"}},[_c('i',{staticClass:"bi bi-plus"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }