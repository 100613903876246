<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-end align-items-center"
      style="background: #0d6efd; color: #fff"
    >
      <router-link
        to="/menu"
        class="btn btn-lg btn-success m-2"
        ><i class="bi bi-x-lg"></i
      ></router-link>
    </div>
    <div
      class="w-100 p-2 pb-5 flex-grow-1"
      style="overflow: auto"
    >
      <h4 class="pb-2">
        Magazyn: {{ locationName ? locationName : "[WYBIERZ MAGAZYN]"
        }}<button
          @click="showLista = !showLista"
          class="btn btn-outline-secondary float-end pull-end"
        >
          Zmień
        </button>
      </h4>
      <ul
        class="list-group"
        v-if="showLista"
      >
        <li
          v-for="item in storages"
          class="list-group-item"
          :key="item.location_id"
        >
          {{ item.location_name }}
          <button
            @click="wybierz(item)"
            class="btn btn-outline-primary float-end pull-end"
          >
            Wybierz
          </button>
        </li>
      </ul>

      <MagicoCheckbox
        @change="save"
        v-model="settings.production_tile"
        :true-value="'1'"
        :false-value="'0'"
        >Kafelek produkcji</MagicoCheckbox
      >
      <MagicoCheckbox
        @change="save"
        v-model="settings.legalization_tile"
        :true-value="'1'"
        :false-value="'0'"
        >Kafelek procesu legalizacji</MagicoCheckbox
      >
      <MagicoCheckbox
        @change="save"
        v-model="settings.cylinderadd_tile"
        :true-value="'1'"
        :false-value="'0'"
        >Kafelek dodawania butli</MagicoCheckbox
      >
      <MagicoCheckbox
        @change="save"
        v-model="settings.service_tile"
        :true-value="'1'"
        :false-value="'0'"
        >Kafelek serwisu</MagicoCheckbox
      >
      <MagicoCheckbox
        @change="save"
        v-model="settings.print_service_labels"
        :true-value="'1'"
        :false-value="'0'"
        >Drukowanie etykiet serwisowych</MagicoCheckbox
      >
      <div>
        <label>ID drukarki</label>
        <input
          @change="save"
          type="text"
          v-model="settings.printer_id"
          class="form-control"
        />
      </div>
      <div>
        <label>Automtycznie wylogowanie po minutach:</label>
        <input
          @change="save"
          type="text"
          v-model="settings.autologoff"
          class="form-control"
        />
      </div>
    </div>
  </div>
</template>

<script>
import myApi from "@/api/myApi";
import MagicoCheckbox from "@/components/MagicoCheckbox.vue";

export default {
  name: "SettingsView",
  components: { MagicoCheckbox },
  props: {},
  computed: {
    locationName() {
      return this.$store.state.location_name;
    },
  },
  data: function () {
    return {
      storages: [],
      showLista: false,
      settings: {
        production_tile: 0,
        legalization_tile: 0,
        service_tile: 0,
        cylinderadd_tile: 0,
        print_service_labels: 0,
        printer_id: "",
        autologoff: "30",
      },
    };
  },
  methods: {
    save: function () {
      for (const key in this.settings) {
        localStorage.setItem(key, this.settings[key]);
      }
    },
    wybierz(location) {
      this.$store.commit("changeLocation", location);
      this.showLista = false;
    },
    loadStorages: function () {
      myApi.findStorages("magazyn").then(
        (response) => {
          this.storages = response.data;
        },
        (e) => {
          console.log("storages", e);
        }
      );
    },
    loadSettings: function () {
      for (const key in this.settings) {
        const value = localStorage.getItem(key);
        if (value !== null) {
          this.settings[key] = value;
        }
      }
    },
  },
  created() {
    this.loadStorages();
    this.showLista = false;
    this.loadSettings();
  },
  mounted() {},
};
</script>

<style scoped></style>
